import { apiCall } from '../utils/api.util';

export const createOrModifyDevice = async (firebaseUID, token, platform) => {
  const newDevice = {
    firebaseUID,
    token,
    platform,
  };

  return await apiCall.post('/devices', newDevice);
};

export const DEFAULT_LANG = 'es';
export const LANGS = {
  SPANISH: 'es',
  ENGLISH: 'en',
  EUSKERA: 'eu',
};
export const SUPPORTED_LANGS = [LANGS.SPANISH, LANGS.ENGLISH, LANGS.EUSKERA];

export const FLAGS = {
  [LANGS.SPANISH]: '🇪🇸',
  [LANGS.ENGLISH]: '🇬🇧',
  [LANGS.EUSKERA]: '',
};

export const EMAIL_PARTNER_WHITELIST = ['imqsalud'];
export const PARTNERS_WITH_NOTIFICATION_INTEGRATIONS = ['imqsalud'];
export const ASSISTANT_MINUMUM_ANDROID_VERSION = 13;

export const ROUTES = {
  path: '/',
  children: [
    {
      path: 'home',
      children: [
        { path: 'session' },
        { path: 'catalog' },
        { path: 'office' },
        { path: 'progress' },
        { path: 'chat' },
        { path: 'profile' },
        { path: 'rehab' },
      ],
    },
    { path: 'login' },
    { path: 'logout' },
    { path: 'app' },
    {
      path: 'onboarding',
      children: [
        { path: 'intro' },
        { path: 'start' },
        { path: 'gender' },
        { path: 'age' },
        { path: 'goal' },
        { path: 'disabilities' },
        { path: 'diagnosis' },
        {
          path: 'cervical',
          children: [
            { path: 'zone' },
            {
              path: 'localized',
              children: [
                { path: 'asociation' },
                { path: 'painStart' },
                { path: 'intensity' },
                { path: 'howLong' },
                { path: 'positionHeld' },
                { path: 'tenderness' },
                { path: 'stiffness' },
                {
                  path: 't',
                  children: [
                    { path: 'intensity' },
                    { path: 'howLong' },
                    { path: 'autoAccident' },
                    { path: 'limitation' },
                    { path: 'disability' },
                  ],
                },
              ],
            },
            {
              path: 'head',
              children: [
                { path: 'asociation' },
                { path: 'intensity' },
                { path: 'bilateral' },
                { path: 'headSymthoms' },
                { path: 'tenderness' },
                { path: 'positionHeld' },
                {
                  path: 't',
                  children: [
                    { path: 'intensity' },
                    { path: 'bilateral' },
                    { path: 'headSymthoms' },
                    { path: 'autoAccident' },
                    { path: 'tenderness' },
                    { path: 'howLong' },
                  ],
                },
              ],
            },
            {
              path: 'both',
              children: [
                { path: 'asociation' },
                { path: 'painStart' },
                { path: 'intensity' },
                { path: 'symthoms' },
                { path: 'howLong' },
                { path: 'limitation' },
                { path: 'tenderness' },
                { path: 'positionHeld' },
                {
                  path: 't',
                  children: [
                    { path: 'intensity' },
                    { path: 'bilateral' },
                    { path: 'symthoms' },
                    { path: 'autoAccident' },
                    { path: 'tenderness' },
                    { path: 'howLong' },
                  ],
                },
              ],
            },
            {
              path: 'irradiated',
              children: [
                { path: 'asociation' },
                { path: 'intensity' },
                { path: 'tingle' },
                { path: 'handColor' },
                { path: 'tenderness' },
                { path: 'armsUp' },
                {
                  path: 't',
                  children: [
                    { path: 'intensity' },
                    { path: 'howLong' },
                    { path: 'autoAccident' },
                    { path: 'tingle' },
                    { path: 'handColor' },
                    { path: 'limitation' },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: 'dorsal',
          children: [
            { path: 'zone' },
            {
              path: 'lateral',
              children: [
                { path: 'trauma' },
                { path: 'painType' },
                { path: 'intensity' },
                { path: 'breath' },
                { path: 'howLong' },
              ],
            },
            {
              path: 'inferior',
              children: [
                { path: 'sit' },
                { path: 'irradiated' },
                { path: 'column' },
                { path: 'breath' },
                { path: 'inclination' },
              ],
            },
            {
              path: 'interescapular',
              children: [
                { path: 'sit' },
                { path: 'irradiated' },
                { path: 'breath' },
                { path: 'scapulae' },
                { path: 'inclination' },
              ],
            },
          ],
        },
        {
          path: 'lumbar',
          children: [
            { path: 'zone' },
            {
              path: 'localized',
              children: [
                { path: 'painZone' },
                { path: 'specificMovement' },
                { path: 'stiffness' },
                { path: 'hiperextention' },
                { path: 'touchingPain' },
              ],
            },
            {
              path: 'irradiatedFoot',
              children: [{ path: 'from' }, { path: 'to' }, { path: 'slump' }, { path: 'tingle' }, { path: 'weakness' }],
            },
            {
              path: 'irradiatedThigh',
              children: [
                { path: 'painZone' },
                { path: 'specificMovement' },
                { path: 'stiffness' },
                { path: 'tingle' },
                { path: 'slump' },
              ],
            },
          ],
        },
        {
          path: 'higiene',
          children: [
            { path: 'intro' },
            { path: 'back-type' },
            { path: 'tenderness' },
            { path: 'cervical-test' },
            { path: 'dorsal-test' },
            { path: 'lumbar-test' },
          ],
        },
        {
          path: 'prevention',
          children: [
            { path: 'goal' },
            { path: 'tenderness' },
            { path: 'back-type' },
            { path: 'cervical-test' },
            { path: 'dorsal-test' },
            { path: 'lumbar-test' },
          ],
        },
        { path: 'profesionalOpinion' },
        { path: 'verifyResults' },
        { path: 'resultsIntro' },
        { path: 'results' },
        { path: 'job' },
        { path: 'difficulty' },
        { path: 'material' },
        { path: 'days' },
      ],
    },
    { path: 'loading' },
    { path: 'session' },
  ],
};

export const isLangSupported = (lang) => {
  return SUPPORTED_LANGS.includes(lang);
};

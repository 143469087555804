export function fullScreen() {
  const { Android } = window;

  if (typeof Android !== typeof undefined) {
    Android.fullScreen();
    Android.landscape();
  } else {
    if (document.body.requestFullScreen) {
      document.body.requestFullScreen();
    } else if (document.body.mozRequestFullScreen) {
      document.body.mozRequestFullScreen();
    } else if (document.body.webkitRequestFullScreen) {
      document.body.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
    } else if (document.body.msRequestFullscreen) {
      document.body.msRequestFullscreen();
    }
  }
}

export function normalScreen() {
  const { Android } = window;

  if (typeof Android !== typeof undefined) {
    Android.normalScreen();
    Android.portrait();
  } else {
    if (document.cancelFullScreen) {
      document.cancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }
}

//? https://stackoverflow.com/questions/4460205/detect-ipad-iphone-webview-via-javascript
export function isWebviewOnIOS() {
  const standalone = navigator.standalone;
  const userAgent = navigator.userAgent.toLowerCase();
  const safari = /safari/.test(userAgent);
  const isIOS = /iphone|ipod|ipad/.test(userAgent);
  const platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown';
  const isIpadOS = navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(platform);

  if ((isIOS || isIpadOS) && !standalone && !safari) return true;
  return false;
}

export function inIframe() {
  try {
    if (typeof window === typeof undefined) return false;
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function isIOS() {
  const userAgent = navigator.userAgent.toLowerCase();
  return /iphone|ipod|ipad/.test(userAgent);
}

export function isWebviewOnAndroid() {
  return typeof Android !== typeof undefined || navigator.userAgent.indexOf('wv') > -1;
}

export function isWebview() {
  return isWebviewOnIOS() || isWebviewOnAndroid();
}

export const materialTranslator = {
  THERABAND: 'Goma elástica',
  CHAIR: 'Silla',
  DUMBBELLS: 'Pesa',
  LONG_ROLLER: 'Churro',
  TOWEL: 'Toalla',
  FOAMROLLER: 'Rodillo',
  BALL: 'Pelota',
  FITBALL: 'Fitball',
  CREAM: 'Crema',
};

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, setDoc, collection, getDoc, doc } from 'firebase/firestore';
import { getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging';
import { onAuthStateChanged } from '@firebase/auth';
import { createOrModifyDevice } from '../services/DeviceService';
import { isWebviewOnIOS } from '../components/app/helpers';

const firebaseApp = initializeApp({
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
});
export const firestore = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);

/**
 * Returns the Bearer token to be used in the headers of the requests.
 * @returns {Promise<{Authorization: string}>}
 */
export const getAuthHeader = async () => {
  const user = auth.currentUser;
  const idToken = await user.getIdToken();
  return { Authorization: `Bearer ${idToken}` };
};

export const addUserFromFirebase = async (userObj) => {
  try {
    const userDocRef = doc(collection(firestore, 'sss_users'), userObj.user);
    const res = await setDoc(userDocRef, userObj);
    console.log('Document written with ID: ', res);
  } catch (e) {
    console.error('Error adding document: ', e);
  }
};

export const getUserFromFirebase = async (user) => {
  try {
    const userDoc = await getDoc(doc(firestore, 'sss_users', user));
    return userDoc?.data();
  } catch (e) {
    console.error('Error getting document: ', e);
    return undefined;
  }
};

onAuthStateChanged(auth, async (user) => {
  if (typeof window === 'undefined') return;
  // if: Android device || else-if: IOS device || else: is web device
  const { Android } = window;

  if (typeof Android !== typeof undefined) {
    if (Android.sendDeviceAndroid) {
      const uid = user ? user.uid : null;
      return Android.sendDeviceAndroid(uid);
    }
  } else if (isWebviewOnIOS() === true) {
    if (window.registerDeviceInIOS) {
      const uid = user ? user.uid : null;
      return window.registerDeviceInIOS(uid);
    }
  } else {
    await sendDeviceWeb(user);
  }
});

const sendDeviceWeb = async (user) => {
  try {
    const isSupportedBrowser = isSupported();

    if (!isSupportedBrowser) {
      console.log('Firebase messaging is not supported in this browser.');
      return;
    }

    const messaging = getMessaging();

    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
    });

    const token = await getToken(messaging, {
      vapidKey: process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_VAPID_KEY,
    });
    if (user) {
      const uid = user.uid;
      console.log(`User with uid ${uid} is logged in with token ${token}`);
      await createOrModifyDevice(uid, token, 'web');
    } else {
      console.log(`User is logged out with token ${token}`);
      await createOrModifyDevice(null, token, 'web');
    }
  } catch (error) {
    console.log('An error ocurred while retrieving token. ', error);
  }
};

export default firebaseApp;

import { AxiosError, AxiosRequestConfig } from 'axios';
import { auth } from '../../auth/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { jwtDecode } from 'jwt-decode';

export const VITALY_PARTNER_CODE = 'vitaly';

enum VitalyKeysAction {
  VITALY = 'VITALY',
  CHILD_LOADED = 'CHILD_LOADED',
  UNAUTHORIZED = 'UNAUTHORIZED',
  CLOSE = 'CLOSE',
  FORBIDDEN = 'FORBIDDEN',
  MENU = 'MENU',
  CLIPBOARD = 'CLIPBOARD',
  DOWNLOAD = 'DOWNLOAD',
  BLOB = 'BLOB',
}

interface VitalyFrameMessage {
  key: VitalyKeysAction;
  type?: VitalyKeysAction;
  token?: string;
  callback?: string;
  clipboard?: string;
  data?: any;
}

/**
 * Checks if the current implementation is for the Vitaly service, checking for partner or if page is inside an <iframe> tag.
 *
 * @returns {boolean} Returns true if the implementation is for Vitaly, false otherwise.
 */
export const isVitalyImplementation = () =>
  localStorage.getItem('partner') === VITALY_PARTNER_CODE || window !== window.parent;

/**
 * Listens for Vitaly messages and performs necessary actions based on the received data.
 * @param e - The MessageEvent object containing the data received from Vitaly.
 */
export const vitalyMessagesListener = async (e: MessageEvent) => {
  const data = typeof e.data === 'string' ? JSON.parse(e.data) : e.data;
  if (data.key === VitalyKeysAction.VITALY) {
    if (!localStorage.getItem('partner') || localStorage.getItem('partner') !== VITALY_PARTNER_CODE) {
      localStorage.setItem('partner', VITALY_PARTNER_CODE);
    }
    const message: VitalyFrameMessage = { ...data };
    if (message.token) {
      const initialyHasVitalyToken = localStorage.getItem('vitalyToken');
      localStorage.setItem('vitalyToken', message.token);
      const payload = jwtDecode<any>(message.token);
      await signInWithEmailAndPassword(auth, payload.email, `000000${payload.vitaly_user_id}`);
      if (message.callback || !initialyHasVitalyToken) window.location.reload();
    }
  }
};

export const transformVitalyRequestHeaders = async (config: AxiosRequestConfig) => {
  const vitalyToken = localStorage.getItem('vitalyToken');
  const user = auth.currentUser;
  const fisifyToken = await user?.getIdToken();
  if (config.headers) {
    if (vitalyToken) config.headers.Authorization = `Bearer ${vitalyToken}`;
    if (fisifyToken) config.headers['Fisify-Authorization'] = `Bearer ${fisifyToken}`;
  }

  return config;
};

/**
 * Handles Vitaly service errors and performs necessary actions based on the error status.
 * @param error - The Axios error object.
 * @returns The original error object.
 */
export const handleVitalyError = (error: AxiosError) => {
  let message: VitalyFrameMessage;
  if (error.response?.status === 401) {
    message = {
      key: VitalyKeysAction.VITALY,
      type: VitalyKeysAction.UNAUTHORIZED,
      callback: window.location.pathname,
    };
    window.parent.postMessage(message, '*');
  } else if (error.response?.status === 403) {
    message = { key: VitalyKeysAction.VITALY, type: VitalyKeysAction.CLOSE };
    window.parent.postMessage(message, '*');
  }

  return error.config;
};

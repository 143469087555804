import axios from 'axios';
import { auth } from '../auth/firebase';
import {
  VITALY_PARTNER_CODE,
  handleVitalyError,
  transformVitalyRequestHeaders,
} from '../services/integrations/VitalyService';

/**
 * Retrieves the base URL based on the partner code stored in the local storage.
 * It returns the backend URL specified in the environment variables if not controlled partner is on local storage.
 *
 * @returns {string} The base URL.
 */
export const getBaseURL = () => {
  let baseURL;
  if (typeof localStorage !== 'undefined' && localStorage.getItem('partner') === VITALY_PARTNER_CODE) {
    baseURL = process.env.NEXT_PUBLIC_VITALY_GATEWAY_URL;
  } else {
    baseURL = process.env.NEXT_PUBLIC_BACKEND_URL;
  }

  return baseURL;
};

/**
 * Creates an instance of axios with a base URL and default headers for API calls.
 * @type {import('axios').AxiosInstance}
 */
export const apiCall = axios.create({
  baseURL: getBaseURL(),
  headers: {
    'Content-Type': 'application/json',
  },
});

/**
 * Adds the authorization token to the headers of the request.
 */
apiCall.interceptors.request.use(
  async (config) => {
    if (localStorage.getItem('partner') === VITALY_PARTNER_CODE) {
      return transformVitalyRequestHeaders(config);
    } else {
      const user = auth.currentUser;
      const token = await user?.getIdToken(true);
      if (token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error),
);

/**
 * Handles errors in the response of the API call.
 */
apiCall.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (localStorage.getItem('partner') === VITALY_PARTNER_CODE) {
      handleVitalyError(error);
    }
    return Promise.reject(error);
  },
);
